import * as React from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {render} from "@testing-library/react";
import jwt_decode from "jwt-decode";


const Dashboard = () => {



    const [searchParams, setSearchParams] = useSearchParams();

    const [data, setData] = useState([]);
    const history = useNavigate();


    useEffect(() => {
        refreshToken();
        getData();
    }, []);

    const refreshToken = async () => {

        try {

            //alert()
            await axios.post('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/cdn/refresh/', { refresh: localStorage.getItem('refreshToken')})

                .then(response => {
                    //alert(response);
                    const newAccessToken = response.data.access;

                    const newRefreshToken = response.data.refresh;

                    localStorage.setItem('accessToken', newAccessToken)

                    localStorage.setItem('refreshToken', newRefreshToken)



                })
        } catch (error) {
            console.log(error);
            if (error.response) {
                history("/");
            }
        }
    }


    const download = async ()  =>  {
        const currentDate = new Date();
        if (localStorage.getItem('expire')<currentDate) await refreshToken();

        const response = await axios.post('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/cdn/get-sim/',
            {file_name:'test.txt'},
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }



        )
            .then(response=>{
                if (response && [301, 302].includes(response.status)) {
                    const redirectUrl = response.headers.location
                    console.log(redirectUrl);
                    return axios.get(redirectUrl);
                }

            })
            .catch(error =>{
                console.log(error);
                if (error.response && [301, 302].includes(error.response.status)) {
                    const redirectUrl = error.response.headers.location
                    console.log(redirectUrl);
                    return axios.get(redirectUrl);
                }
                return Promise.reject(error);
                if (error.code === "ERR_BAD_REQUEST") {



                }
            });




    }



    const getData = async () => {
        const currentDate = new Date();
        if (localStorage.getItem('expire')<currentDate) await refreshToken();

        const response = await axios.get('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/quadro_app/distribution-info/', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },


            }
        )
            .then(response=>{

                console.log(response);
                setData(response.data);
                //console.log(response.data);
            })
            .catch(error =>{
                console.log(error);
                if (error.status=== 401) alert(error.data.detail);
                if (error.code === "ERR_BAD_REQUEST") {
//{"detail":"access_token expired"}    401



                }
            });




    }



    return (
        <>
            <div className="background"></div>
            <div className="dash-container">

                <div className="notch-container">
                    <div className="notch"></div>
                    <div className="container full-height">
                        <h1>Для начала работы с Квадросимом необходимо выполнить следующие действия:</h1>
                        <p>
                            <ul>
                                <li>1. Скачать архив с симулятором</li>
                                <li>2. Распаковать архив в желаемую папку</li>
                                <li>3. Запустить симулятор через исполняемый файл в папке с симулятором</li>
                                <li>4. <a href="https://cdn-s3.quadrosim.ru/guides/%D0%9A%D0%B2%D0%B0%D0%B4%D1%80%D0%BE%D1%81%D0%B8%D0%BC_v1_8_%D0%A0%D1%83%D0%BA%D0%BE%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE_%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F.pdf" className="instruction-main">Познакомьтесь с руководством пользователя</a>
                                </li>
                            </ul>
                        </p>
                        <p>
                        В случае, если на одном компьютере необходимо установить две версии Квадросима, в том числе
                            разных лицензий, воспользуйтесь установочным файлом
                            Квадросим Экстра
                        </p>


                        <p>
                            Обратите внимание, что вторая версия симулятора будет работать обособленно от первой,
                            включая данные лицензии, профили пользователя, импортируемый и экспортируемый контент и т.д.
                        </p>



                    </div>
                    <div className="notch-after"></div>
                </div>
                <div className="table-title">

                    <span className="t-title"> Квадросим 1.8</span>
                </div>
                <div className="table-container">
                    <table>
                        <thead>
                        <tr>
                            <th>Версия</th>
                            <th>ОС</th>
                            <th>Тип Файла</th>
                            <th>Ссылка на скачивание</th>
                            <th>Вес</th>
                            <th>Инструкция по установке</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map(o=>(
                        <tr>
                            <td>{o.version}</td>
                            <td>{o.os}</td>
                            <td>{o.extension}</td>
                            <td><a href={o.get_distributive_url}>Скачать</a></td>
                            <td>{o.file_weight}</td>
                            <td><a href={o.get_instruction_url}>Инструкция</a></td>
                        </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )


}

export default Dashboard;
