import React, { useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const history = useNavigate();

    const Auth = async (e) => {
        e.preventDefault();



        axios.post('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/cdn/auth/', {
            username: username,
            password: password,
        })

            .then(response => {

                if (response.status !== 200) return
                console.log(response);
                localStorage.setItem('accessToken', response.data.access);

                localStorage.setItem('refreshToken', response.data.refresh);

                localStorage.setItem('fio', response.data.fio);

                localStorage.setItem('username', username);

                const currentDate = new Date();
                let expire = new Date();
                console.log(response.data.lifetime);
                const lifetime = response.data.lifetime;
                expire = currentDate.getTime() + lifetime * 1000;
                console.log(expire);
                localStorage.setItem('expire', expire.toString());
                console.log(currentDate.getTime());
                history("/dashboard");
            })

            .catch(error => {
                console.error(error);
                if (error.response) {
                    setError('Неверный логин или пароль'); // Пример сообщения об ошибке
                }
            })




    }

    return (


        <div className="auth-container">
            <div className="auth-form">
                <h1 className="auth-title">Авторизация</h1>
                {error && <div className="message-box visible">{error}</div>}
                <form className="form" onSubmit={Auth}>

                    <div className="form__input">
                        <label htmlFor="login">Логин</label>

                        <input type="text" placeholder="Логин" required value={username}
                               onChange={(e) => setUsername(e.target.value)}/>
                    </div>
                    <div className="form__input">
                        <label htmlFor="password">Пароль</label>

                        <input type="password" placeholder="Пароль" required value={password}
                               onChange={(e) => setPassword(e.target.value)}/>
                    </div>


                    <button type="submit" className="form__button">ВОЙТИ</button>
                </form>
                <div className="instruction"><a href="https://cdn.quadrosim.ru/guides/lk-instruction.pdf">Инструкция по работе с личным кабинетом</a></div>
            </div>

        </div>
    )
}

export default Login
